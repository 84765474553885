import { types } from "./types";
import userWhitelistService from "../../services/userWhitelist";

function changeForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_FORM, data });
    };
}

function defaultRow() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_ROW });
    };
}

function defaultForm() {
    return (dispatch) => {
        dispatch({ type: types.DEFAULT_FORM });
    };
}

function filter(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, form });
        return userWhitelistService.filter(form).then(
            (data) => {
                console.log('qqq', data);
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function fetch(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, form });
        return userWhitelistService.fetch(form).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
export function search(form) {
    return (dispatch) => {
        dispatch({ type: types.SEARCH_REQUEST, form });
        return userWhitelistService.search(form).then(
            (data) => {
                dispatch({ type: types.SEARCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.SEARCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
function createForm() {
    return (dispatch) => {
        dispatch({ type: types.CREATE_FORM });
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

function changeUpdateForm(variableName, form) {
    return (dispatch) => {
        const data = { variableName, form };
        dispatch({ type: types.CHANGE_UPDATE_FORM, data });
    };
}

function reponseDialogClose() {
    return (dispatch) => {
        dispatch({ type: types.POST_UPDATE_FORM_INIT });
    };
}

function startUpdate(form) {
    return (dispatch) => {
        dispatch({ type: types.POST_UPDATE_FORM, form });

        return userWhitelistService.startUpdate(form).then(
            (data) => {
                dispatch({ type: types.POST_UPDATE_FORM_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.POST_UPDATE_FORM_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function fetchSettings() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_SETTINGS_REQUEST });
        return userWhitelistService.getSettings().then(
            (data) => {
                dispatch({ type: types.FETCH_SETTINGS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_SETTINGS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function saveSettings(setting, val) {
    return (dispatch) => {
        const form = {[setting]: val};
        dispatch({ type: types.SAVE_SETTINGS_REQUEST, form });
        return userWhitelistService.saveSettings(form).then(
            (data) => {
                dispatch({ type: types.SAVE_SETTINGS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.SAVE_SETTINGS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    fetch,
    filter,
    changeForm,
    defaultForm,
    defaultRow,
    createForm,
    setInfo,
    changeUpdateForm,
    startUpdate,
    reponseDialogClose,
    fetchSettings,
    saveSettings,
};

export default actions;
