export const types = {
    FETCH_REQUEST: "@@users_whitelist/FETCH_REQUEST",
    FETCH_SUCCESS: "@@users_whitelist/FETCH_SUCCESS",
    FETCH_FAILURE: "@@users_whitelist/FETCH_FAILURE",

    SEARCH_REQUEST: "@@users_whitelist/SEARCH_REQUEST",
    SEARCH_SUCCESS: "@@users_whitelist/SEARCH_SUCCESS",
    SEARCH_FAILURE: "@@users_whitelist/SEARCH_FAILURE",

    FETCH_SETTINGS_REQUEST: "@@users_whitelist/FETCH_SETTINGS_REQUEST",
    FETCH_SETTINGS_SUCCESS: "@@users_whitelist/FETCH_SETTINGS_SUCCESS",
    FETCH_SETTINGS_FAILURE: "@@users_whitelist/FETCH_SETTINGS_FAILURE",

    SAVE_SETTINGS_REQUEST: "@@users_whitelist/SAVE_SETTINGS_REQUEST",
    SAVE_SETTINGS_SUCCESS: "@@users_whitelist/SAVE_SETTINGS_SUCCESS",
    SAVE_SETTINGS_FAILURE: "@@users_whitelist/SAVE_SETTINGS_FAILURE",

    SEARCH_IMPERSONATION_REQUEST: "@@users_whitelist/SEARCH_IMPERSONATION_REQUEST",
    SEARCH_IMPERSONATION_SUCCESS: "@@users_whitelist/SEARCH_IMPERSONATION_SUCCESS",
    SEARCH_IMPERSONATION_FAILURE: "@@users_whitelist/SEARCH_IMPERSONATION_FAILURE",

    POST_REQUEST: "@@users_whitelist/POST_REQUEST",
    POST_SUCCESS: "@@users_whitelist/POST_SUCCESS",
    POST_FAILURE: "@@users_whitelist/POST_FAILURE",

    GET_REQUEST: "@@users_whitelist/GET_REQUEST",
    GET_SUCCESS: "@@users_whitelist/GET_SUCCESS",
    GET_FAILURE: "@@users_whitelist/GET_FAILURE",

    DEFAULT_FORM: "@@users_whitelist/DEFAULT_FORM",
    CHANGE_FORM: "@@users_whitelist/CHANGE_FORM",
    DEFAULT_ROW: "@@users_whitelist/DEFAULT_ROW",

    CHANGE_UPDATE_FORM: "@@users_whitelist/CHANGE_UPDATE_FORM",
    POST_UPDATE_FORM: "@@users_whitelist/POST_UPDATE_FORM",
    POST_UPDATE_FORM_INIT: "@@users_whitelist/POST_UPDATE_FORM_INIT",
    POST_UPDATE_FORM_SUCCESS: "@@users_whitelist/POST_UPDATE_FORM_SUCCESS",
    POST_UPDATE_FORM_FAILURE: "@@users_whitelist/POST_UPDATE_FORM_FAILURE",

    FETCH_CHAMBERS_GET: "@@users_whitelist/FETCH_CHAMBERS_GET",
    FETCH_CHAMBERS_SUCCESS: "@@users_whitelist/FETCH_CHAMBERS_SUCCESS",
    FETCH_CHAMBERS_FAILURE: "@@users_whitelist/FETCH_CHAMBERS_FAILURE",

    CREATE_FORM: "@@users_whitelist/CREATE_FORM",
    CREATE_REQUEST: "@@users_whitelist/CREATE_REQUEST",
    CREATE_SUCCESS: "@@users_whitelist/CREATE_SUCCESS",
    CREATE_FAILURE: "@@users_whitelist/CREATE_FAILURE",

    UPDATE_REQUEST: "@@users_whitelist/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@users_whitelist/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@users_whitelist/UPDATE_FAILURE",

    SEND_PSW_RESET_REQUEST: "@@users_whitelist/SEND_PSW_RESET_REQUEST",
    SEND_PSW_RESET_SUCCESS: "@@users_whitelist/SEND_PSW_RESET_SUCCESS",
    SEND_PSW_RESET_FAILURE: "@@users_whitelist/SEND_PSW_RESET_FAILURE",

    SEND_ACTIVATION_MAIL_REQUEST: "@@users_whitelist/SEND_ACTIVATION_MAIL_REQUEST",
    SEND_ACTIVATION_MAIL_SUCCESS: "@@users_whitelist/SEND_ACTIVATION_MAIL_SUCCESS",
    SEND_ACTIVATION_MAIL_FAILURE: "@@users_whitelist/SEND_ACTIVATION_MAIL_FAILURE",

    DELETE_REQUEST: "@@users_whitelist/DELETE_REQUEST",
    DELETE_SUCCESS: "@@users_whitelist/DELETE_SUCESS",
    DELETE_FAILURE: "@@users_whitelist/DELETE_FAILURE",

    DELETE_REPEAL_REQUEST: "@@users_whitelist/DELETE_REPEAL_REQUEST",
    DELETE_REPEAL_SUCCESS: "@@users_whitelist/DELETE_REPEAL_SUCCESS",
    DELETE_REPEAL_FAILURE: "@@users_whitelist/DELETE_REPEAL_FAILURE",

    POST_IMPERSONATION_REQUEST: "@@users_whitelist/POST_IMPERSONATION_REQUEST",
    POST_IMPERSONATION_SUCCESS: "@@users_whitelist/POST_IMPERSONATION_SUCCESS",
    POST_IMPERSONATION_FAILURE: "@@users_whitelist/POST_IMPERSONATION_FAILURE",

    FETCH_SET_INFO: "@@users_whitelist/FETCH_SET_INFO",
};
