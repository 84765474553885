import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import {getJWTToken} from "../../utils/AuthHelper";
import axios from "axios";

function filter(form) {
    let helper = Object.assign({}, form);
    const searchForm = new SearchForm(
        helper,
        {

        },
        {

        },
        true
    );
    let sendSearch = searchForm.getSend();

    return API.get("/users/whitelist", { params: sendSearch })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function search(params) {
    return API.get("/users/search", { params })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getSettings() {
    return API.get("/users/whitelist/settings", { })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function startUpdate(form) {
    const AUTH_TOKEN = getJWTToken();
    let formData = new FormData();
    formData.append("file", form.file);

    return axios
        .post(process.env.REACT_APP_API_URL + "/users/whitelist/update", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${AUTH_TOKEN}`,
            },
        })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function saveSettings(form) {
    return API
        .post(process.env.REACT_APP_API_URL + "/users/whitelist/settings", form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const userWhitelistService = {
    filter,
    search,
    startUpdate,
    getSettings,
    saveSettings,
};

export default userWhitelistService;
