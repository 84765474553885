import { types } from "./types";

const initialLanguages = { hu: null, en: null };

const initInfo = {
    filter: {},
    sort: {
        email: "asc",
    },
    page: 1,
    perpage: 25,
    metadata: {},
};

const initialState = {
    loading: false,
    loadingForm: false,
    data: [],
    search: {
        name: null,
        short_name: null,
        active: null,
    },
    row: {
        name: initialLanguages,
        short_name: initialLanguages,
        active: false,
    },
    updateForm: {
        file: null,
    },
    updateFormResponse: null,
    tableLoading: true,
    info: initInfo,
    settingsForm: {
        loaded: false,
        form: {},

    }
};

export function userWhitelist(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_UPDATE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.POST_UPDATE_FORM_INIT:
            return {
                ...state,
                updateFormResponse: initialState.updateFormResponse,
                updateForm: initialState.updateForm,
                loadingForm: initialState.loadingForm,
            };
        case types.POST_UPDATE_FORM:
            return {
                ...state,
                updateFormResponse: null,
                loadingForm: true,
            };
        case types.POST_UPDATE_FORM_SUCCESS:
            return {
                ...state,
                updateFormResponse: action.data.data,
                loadingForm: false,
            };
        case types.POST_UPDATE_FORM_FAILURE:
            return {
                ...state,
                loadingForm: false,
            };
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.variableName]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                search: initialState.search,
                info: initInfo,
            };
        case types.DEFAULT_ROW:
            return {
                ...state,
                row: initialState.row,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
            }
        case types.SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
                search: action.form,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                info: {
                    ...state.info,
                    metadata: action?.data?.data?.metadata,
                },
                data: action?.data?.data?.data,
            };
        case types.SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
        case types.SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.POST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_SUCCESS:
            return {
                ...state,
                loading: false,
                row: action.data.data,
            };
        case types.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                row: initialState.row,
                error: action.error,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data,
            };
        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                users: action.data.data,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                users: action.data.data,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case types.FETCH_SET_INFO:
            return {
                ...state,
                info: {
                    ...state.info,
                    [action.key]: action.value,
                },
            };

        case types.FETCH_SETTINGS_REQUEST:
            return {
                ...state,
                settingsForm: {
                    ...state.settingsForm,
                    loaded: false,
                }
            };


        case types.SAVE_SETTINGS_REQUEST:
            return {
                ...state,
                settingsForm: {
                    ...state.settingsForm,
                    loaded: false,
                }
            };

        case types.SAVE_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsForm: {
                    ...state.settingsForm,
                    loaded: true,
                    form: action.data.data,
                }
            };

        case types.FETCH_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsForm: {
                    ...state.settingsForm,
                    loaded: true,
                    form: action.data.data,
                }
            };

        default:
            return state;
    }
}
