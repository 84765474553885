import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import userWhitelistActions from "../../../store/userWhitelist/actions.js";
import { connect } from "react-redux";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import Table from "../../Common/Table";
import i18next from "i18next";

const btnRendererStyle = makeStyles((theme) => ({
    deleteButton: {
        padding: theme.spacing(1),
    },
}));

function UserWhitelistTable(props) {

    const table = {
        columnDefs: [
            {
                headerName: i18next.t("users.email"),
                field: "email",
                cellRenderer: "defaultCellRenderWithTooltip",
                minWidth: 275,
            },
        ],
        loading: props.tableLoading,
        rowData: props.data,
        suppressColumnVirtualisation: true,
        frameworkComponents: {
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
        autoSize: "true",
        filterData: props.filter,
        searchfunc: props.filter,
        info: props.info,
        setInfo: props.setInfo,
        isPaginated: true,
        context: {
            filter: props.filter,
            searchData: props.search,
            userLang: props.userLang,
        },
        newButtonText: i18next.t("users.whitelist.import.title"),
        to: "/users/whitelist/update",
        minWidth : 100,
        hidePagination: true
    };

    return (
        <Table {...table} />
    );
}

function mapStateToProps(state) {
    return {
        userLang: state.authentication.user.language,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        filter: (search) => dispatch(userWhitelistActions.filter(search)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserWhitelistTable);
