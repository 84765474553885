import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import { authentication } from "./login/reducer";
import { chambers } from "./chambers/reducer";
import { settings } from "./settings/reducer";
import { courses } from "./courses/reducer";
import { emailTemplates } from "./emailTemplates/reducer";
import { emailLog } from "./emailLog/reducer";
import { systemLog } from "./systemLog/reducer";
import { translations } from "./translations/reducer";
import { permissions } from "./permissions/reducer";
import { notifications } from "./notifications/reducer";
import { news } from "./news/reducer";
import { enrollment } from "./enrollment/reducer";
import { systemMessages } from "./systemMessages/reducer";
import { users } from "./users/reducer";
import { userWhitelist } from "./userWhitelist/reducer";
import { validator } from "./validator/reducer";
import { changePassword } from "./changePassword/reducer";
import { pages } from "./pages/reducer";
import { main } from "./main/reducer";
import { elearningCourseCategories } from "./elearningCourseCategories/reducer";
import { elearningExamManagement } from "./elearningExamManagement/reducer";
import { elearningCourseGeneration } from "./elearningCourseGeneration/reducer";
import { elearningCourseEnrollments } from "./elearningCourseEnrollments/reducer";
import { elearningCourseAchievements } from "./elearningCourseAchievements/reducer";
import { elearningExamGeneration } from "./elearningExamGeneration/reducer";
import { elearningExamEnrollmentDismissal } from "./elearningExamEnrollmentDismissal/reducer";
import { importData } from "./importData/reducer";
import { loading } from "./loading/reducer";

export const history = createBrowserHistory();
const loggerMiddleware = createLogger();

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        authentication,
        chambers,
        changePassword,
        settings,
        emailTemplates,
        emailLog,
        systemLog,
        translations,
        permissions,
        notifications,
        news,
        enrollment,
        courses,
        systemMessages,
        users,
        userWhitelist,
        validator,
        pages,
        main,
        elearningCourseCategories,
        elearningExamManagement,
        elearningCourseGeneration,
        elearningCourseEnrollments,
        elearningCourseAchievements,
        elearningExamGeneration,
        elearningExamEnrollmentDismissal,
        importData,
        loading,
    });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware, loggerMiddleware))
);
