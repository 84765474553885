import Typography from "@material-ui/core/Typography";
import React from "react";
import i18next from "i18next";

// Időintervallum ellenőrző függvény
function isWithinDisplayTime() {
    const now = new Date();

    // Időintervallum
    const startDate = new Date("2025-01-06T09:00:00");
    const endDate = new Date("2025-01-22T13:00:00");

    // Ellenőrzések
    return now >= startDate && now <= endDate;
}

export default function CompetitionTitle() {
    if(!isWithinDisplayTime()){
        return null;
    }

    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{
            fontWeight: 700,
            fontSize: '17px',
            color: '#E57373'
        }}>
            {i18next.t("competitiontitle")}
        </Typography>
    );
}
