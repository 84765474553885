import React, {useEffect} from "react";
import { Grid } from "@material-ui/core";
import Header from "../../Common/Header";
import TxtField from "../../Common/TxtField";
import userWhitelistActions from "../../../store/userWhitelist/actions.js";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../../Common/Loading";
import Filter from "../../Common/Filter";
import i18next from "i18next";
import { setInfo } from "../../../utils/FilterHelper";
import UserWhitelistTable from "./UserWhitelistTable";
import UserWhitelistSettingForm from "./UserWhitelistSettingForm";

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        marginTop: theme.spacing(1),
    },
}));

function UserWhitelist(props) {
    const classes = useStyles();
    const { loading, data, search, user } = props;

    useEffect(() => {
        props.filter(search);
    }, []);

    const handleChange = (event) =>
        props.changeForm("search", {
            ...search,
            [event.target.name]: event.target.value,
        });

    const propsSearch = {
        ...props,
        search: props.filter
    };

    const resetForm = (event) => {
        event.preventDefault();
        props.defaultForm();
        props.filter({});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setInfo(propsSearch, "filter", search, 'page', 1);
        //props.filter(search);
    };

    const filtForm = (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <TxtField
                    onChange={handleChange}
                    value={search.email || ""}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label={i18next.t("users.email")}
                    id="email"
                    name="email"
                />
            </Grid>
        </>
    );

    return (
        <Grid container spacing={2}>
            <Header
                title={i18next.t("sidebar.users")}
                breadcrumbs={{
                    master_datas: i18next.t("sidebar.master_data"),
                    users: i18next.t("sidebar.users"),
                    whitelist: i18next.t("sidebar.users.whitelist"),
                }}
            />
            <UserWhitelistSettingForm />
            {loading ? (
                <Loading />
            ) : (
                <Filter
                    filterForm={filtForm}
                    defaultForm={(e) => resetForm(e)}
                    onSubmit={handleSubmit}
                />
            )}
            <Grid item container xs={12}>
                <UserWhitelistTable
                    loading={props.loading}
                    tableLoading={props.tableLoading}
                    data={data}
                    user={user}
                    search={search}
                    filter={search}
                    info={props.info}
                    setInfo={props.setInfo}
                />
            </Grid>
        </Grid>
    );
}

function mapState(state) {
    const { loading, search, data, chambers, info, tableLoading } = state.userWhitelist;
    const { user } = state.authentication;
    return { loading, search, data, user, chambers, info, tableLoading };
}

const actionCreators = {
    filter: userWhitelistActions.filter,
    changeForm: userWhitelistActions.changeForm,
    defaultForm: userWhitelistActions.defaultForm,
    setInfo: userWhitelistActions.setInfo,
};

export default connect(mapState, actionCreators)(UserWhitelist);
