import React, {useEffect} from "react";
import {Accordion, AccordionDetails, FormControlLabel, FormGroup, Grid, Typography} from "@material-ui/core";
import userWhitelistActions from "../../../store/userWhitelist/actions.js";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import i18next from "i18next";
import colors from "../../../styles/colors.scss";
import GreenSwitch from "../../Common/GreenSwitch";
import notificationsActions from "../../../store/notifications/actions";
import Loading from "../../Common/Loading";
import AccordionSum from "../../Common/AccordionSum";

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        marginTop: theme.spacing(1),
    },
}));

function UserWhitelistSettingForm(props) {
    const classes = useStyles();
    const { settingsForm } = props;

    useEffect(() => {
        props.fetchSettings();
    }, []);

    const handlewhitelistEnabledChange = (e) => {
        props.saveSettings('whitelistEnabled', !settingsForm.form.whitelistEnabled).then(() => {
            props.addNotification("success", 'Sikeres mentés!');
        })
    }

    if(!settingsForm.loaded){
        return <Loading />;
    }

    return (
        <Grid item xs={12}>
            <Accordion expanded>
                <AccordionSum id="panel1a-header" text={i18next.t('users.whitelist.title2')} />
                <AccordionDetails style={{display: "block"}}>
                <FormGroup>
                    <FormControlLabel
                        label={
                            <Typography style={{ color: colors.black }}>
                                {i18next.t('users.whitelist.title')}
                            </Typography>
                        }
                        control={
                            <GreenSwitch
                                checked={settingsForm.form.whitelistEnabled}
                                onChange={handlewhitelistEnabledChange}
                                name="whitelistEnabled"
                                id="whitelistEnabled"
                            />
                        }
                    />
                </FormGroup>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

function mapState(state) {
    const { settingsForm } = state.userWhitelist;
    return { settingsForm };
}

const actionCreators = {
    fetchSettings: userWhitelistActions.fetchSettings,
    saveSettings: userWhitelistActions.saveSettings,
    addNotification: notificationsActions.addNotification,
};

export default connect(mapState, actionCreators)(UserWhitelistSettingForm);
