import {
    Accordion,
    AccordionSummary,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Grow,
    LinearProgress,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from "@material-ui/core";
import { ArrowDropDown, ExpandMore } from "@material-ui/icons";
import i18next from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import notificationsActions from "../../../store/notifications/actions.js";
import userActions from "../../../store/users/actions.js";
import CancelButton from "../../Common/CancelButton";
import SubmitButton from "../../Common/SubmitButton";
import elearningCourseEnrollments from "../../../store/elearningCourseEnrollments/actions";

const IncorrectElements = ({ elements }) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>Hibás elemek ({elements.length})</AccordionSummary>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    maxHeight: "200px",
                    overflow: "auto",
                    padding: "16px",
                }}
            >
                {elements.map((element) => (
                    <Typography variant="caption">
                        {element.fullname} ({element.email})
                    </Typography>
                ))}
            </div>
        </Accordion>
    );
};

export default function MassOperationDialog(props) {
    const dispatch = useDispatch();
    const { selectedRows } = props;
    const sendCount = selectedRows.filter((element) => !element.status).length;
    const [open, setOpen] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [processDialogOpen, setProcessDialogOpen] = React.useState(false);
    const [counter, setCounter] = React.useState(0);
    const { selectedCourse } = useSelector((state) => state.elearningCourseEnrollments);

    const handleClose = () => {
        setOpen(false);
        setMenuOpen(false);
    };

    const rowsToSend = selectedRows.filter((element) => !element.status);
    const n = rowsToSend.length;

    const getText = () => {
        if (counter < n) {
            return "Folyamatban...";
        }

        if (errors.length > 0) {
            return "Hiba a folyamat során";
        }

        return "Sikeres folyamat";
    };

    const getColor = () => {
        if (counter < n) {
            return "#555";
        }

        if (errors.length > 0) {
            return "#e57681";
        }

        return "#008554";
    };

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef}>
                <Button onClick={() => setMenuOpen(true)}>{i18next.t("course_enrolled.mass_operation")}</Button>
                <Button size="small" onClick={() => setMenuOpen(true)}>
                    <ArrowDropDown />
                </Button>
                <Popper
                    style={{
                        zIndex: 1,
                    }}
                    open={menuOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        <MenuItem key={"mass_operation"} onClick={() => setOpen(true)}>
                                            {i18next.t("course_enrolled.resend_email")}
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </ButtonGroup>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogContent>
                    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                        <Grid item>
                            {n === 0 ? 
                            <Typography>{i18next.t("users.activation.already_activated")}</Typography>
                            :
                            <>
                                <Typography>{i18next.t("course_enrolled.certainity_question")}</Typography>
                                <Typography>{i18next.t("course_enrolled.mass_email", { count: sendCount })}</Typography>
                            </>
                            }
                        </Grid>
                    </Grid>
                    <DialogActions>
                        {n === 0 ?
                        <CancelButton text={i18next.t("close")} onClick={handleClose} />
                        :
                        <>
                            <CancelButton text={i18next.t("no")} onClick={handleClose} />
                            <SubmitButton
                                text={i18next.t("yes")}
                                onClick={async () => {
                                    setProcessDialogOpen(sendCount > 0);

                                    let i = 0;
                                    let maxChunk = 20;
                                    while (i < rowsToSend.length) {
                                        const chunk = Math.ceil(Math.random() * maxChunk);
                                        const promises = rowsToSend.slice(i, i + chunk).map((element) => {
                                            return new Promise((resolve) => {
                                                dispatch(userActions.sendActivationMail(element.id))
                                                    .catch(() => {
                                                        setErrors((prev) => [...prev, element]);
                                                    })
                                                    .finally(() => {
                                                        setCounter((prev) => prev + 1);
                                                        handleClose();
                                                        resolve();
                                                    });
                                            });
                                        });
                                        await Promise.all(promises);
                                        i += chunk;
                                    }

                                    if (errors.length > 0) {
                                        dispatch(notificationsActions.addNotification("error", i18next.t("users.error")));
                                    } else {
                                        dispatch(
                                            notificationsActions.addNotification(
                                                "success",
                                                i18next.t("users.activation.success")
                                            )
                                        );
                                    }
                                }}
                            />
                        </>
                    }
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={processDialogOpen} fullWidth maxWidth="sm">
                <DialogTitle style={{ backgroundColor: "#008554", color: "#fff" }}>
                    Aktiváló e-mail újraküldése
                </DialogTitle>
                <DialogContent style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Typography variant="body1">Aktiváló e-mailek generálása folyamatban...</Typography>
                    <Typography variant="caption">Kérjük, ne zárja be az oldalt, amíg a folyamat tart!</Typography>
                    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <Typography variant="body2" style={{ textAlign: "center", color: "#555" }}>
                            {counter} / {n}
                        </Typography>
                        <LinearProgress value={Math.round((counter / n) * 100)} variant="determinate" />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="caption" style={{ textAlign: "right", color: getColor() }}>
                                {getText()}
                            </Typography>
                            <Typography variant="caption" style={{ textAlign: "right", color: "#555" }}>
                                {Math.round((counter / n) * 100)}%
                            </Typography>
                        </div>
                        {counter === n && errors.length > 0 && <IncorrectElements elements={errors} />}
                    </div>
                </DialogContent>
                <DialogActions>
                    <SubmitButton
                        text="Bezár"
                        onClick={() => {
                            setProcessDialogOpen(false);
                            setCounter(0);
                            dispatch(elearningCourseEnrollments.get(selectedCourse.id));
                        }}
                        disabled={counter !== n}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}
