import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Box, Button, ButtonGroup, Grid, IconButton} from "@material-ui/core";
import UsersTooltipTable from "./UsersTooltipTable";
import userActions from "../../../store/users/actions.js";
import { connect } from "react-redux";
import notificationsActions from "../../../store/notifications/actions.js";
import { defaultCellRenderWithTooltip } from "../../../utils/AgGridPlussFn";
import { TableCellText } from "../../Common/TableCellText";
import Table from "../../Common/Table";
import OperationButton from "../../Common/OperationButton";
import i18next from "i18next";
import moment from "moment";
import LinkToButton from "../../Common/LinkToButton";
import SubmitButton from "../../Common/SubmitButton";
import {hasRole} from "../../../utils/AuthHelper";
import {ROLES} from "../../../utils/AppConst";

const btnRendererStyle = makeStyles((theme) => ({
    deleteButton: {
        padding: theme.spacing(1),
    },
}));

function InfoCellRenderer(params) {
    return <UsersTooltipTable data={params.data} />;
}

function TypeCellRenderer(params) {
    switch (params.data.type) {
        case 0:
            return i18next.t("users.normal");
        case 1:
            return i18next.t("isziir");
        default:
            return "-";
    }
}

function LastLoginCellRenderer(params) {
    return <TableCellText val={params.data.last_login || "-"} />;
}

function ExternalCellRenderer(params) {
    return params.data.external_user ? i18next.t("yes") : i18next.t("no");
}

function OperationsCellRenderer(params) {
    const classes = btnRendererStyle();
    //const classes = useStyles(theme);// FIXME: Ezt hogy kell lekérdezni??

    if (params.data.deleted_at == null) {
        return (
            <>
                <OperationButton type="edit" to={`/users/edit/${params.data.id}`} className={"unset"} />
                <Box component="span" marginX={0.2} />
                {params.data.type == 0 && <>
                <OperationButton
                    params={params}
                    type={params.data.activated_at == null ?
                        "activationEmail" :
                        "passwordReminder"}
                    confirmation={
                        params.data.activated_at == null
                            ? i18next.t("users.activation.confirmation")
                            : i18next.t("users.reminder.confirmation")
                    }
                    successmessage={
                        params.data.activated_at == null
                            ? i18next.t("users.activation.success")
                            : i18next.t("users.reminder.success")
                    }
                    errormessage={i18next.t("users.error")}
                    submittext={i18next.t("yes")}
                    canceltext={i18next.t("no")}
                />
                    <OperationButton
                    className={classes.deleteButton}
                    successmessage={i18next.t("users.delete.success")}
                    confirmation={i18next.t("users.delete.confirmation")}
                    type="delete"
                    params={params}
                    />
                </>
                }
            </>
        );
    } else {
        return (<>
            {params.data.type == 0 ?
            <OperationButton
                params={params}
                className={classes.deleteRepealButton}
                type="repeal"
                confirmation={i18next.t("users.repeal.confirmation")}
                successmessage={i18next.t("users.repeal.success")}
                errormessage={i18next.t("users.repeal.error")}
                submittext={i18next.t("ob.repeal")}
            /> : '-'}
        </>
        );
    }
}
function ChambersRenderer(params) {
    const lang = params.context.userLang;

    let printChambers = "";
    if (params.data.chambers && params.data.chambers.length > 0) {
        for (const [chI, chV] of Object.entries(params.data.chambers)) {
            printChambers +=
                chV.short_name[lang] + (chV.active === 0 ? ` '${i18next.t("users.inactive")}'` : "") + ", ";
        }
        printChambers = printChambers.substring(0, printChambers.length - 2);
    } else {
        printChambers = "-";
    }

    return <TableCellText val={printChambers} />;
}

function SubTypeRenderer(params) {
    let subtype = params.data.sub_type === "" || params.data.sub_type === null ? 'normal' : params.data.sub_type;

    let print = "-";
    if (params.data.type == 0){
        print = i18next.t("users.subtype." + subtype);
    }

    return <TableCellText val={print} />;
}

function PasswordExpiresAtCellRenderer(params) {
    return params.data.password_expires_at && new Date() >= new Date(params.data.password_expires_at)
        ? i18next.t("yes")
        : i18next.t("no");
}

function UsersTable(props) {

    const table = {
        columnDefs: [
            {
                headerName: i18next.t("users.type"),
                field: "type",
                cellRenderer: "typeCellRenderer",
                minWidth: 50,
                flex: 0.6,
                sortable: false,
            },
            {
                headerName: i18next.t("users.subtype"),
                field: "sub_type",
                cellRenderer: "subTypeRenderer",
                minWidth: 50,
                flex: 0.6,
                sortable: false,
            },
            {
                headerName: i18next.t("users.last_name"),
                field: "last_name",
                cellRenderer: "defaultCellRenderWithTooltip",
                minWidth: 100,
                flex: 0.75,
            },
            {
                headerName: i18next.t("users.first_name"),
                field: "first_name",
                cellRenderer: "defaultCellRenderWithTooltip",
                minWidth: 100,
                flex: 0.75,
            },
            {
                headerName: i18next.t("users.email"),
                field: "email",
                cellRenderer: "defaultCellRenderWithTooltip",
                minWidth: 275,
            },
            {
                headerName: i18next.t("users.chamber"),
                field: "chambers",
                cellRenderer: "chambersRenderer",
                sortable: false,
            },
            {
                headerName: i18next.t("users.last_login"),
                field: "last_login",
                cellRenderer: "lastLoginCellRenderer",
            },
            {
                headerName: i18next.t("users.password_expires"),
                field: "password_expires_at",
                cellRenderer: "passwordExpiresAtCellRenderer",
                sortable: false,
                minWidth: 100,
            },
            {
                headerName: i18next.t("table.operations"),
                field: "operations",
                cellRenderer: "operationsCellRenderer",
                sortable: false,
                minWidth: 300,
            },
            {
                headerName: i18next.t("users.changes"),
                field: "changes",
                cellRenderer: "infoCellRenderer",
                sortable: false,
            },
        ],
        loading: props.tableLoading,
        rowData: props.data,
        suppressColumnVirtualisation: true,
        frameworkComponents: {
            infoCellRenderer: InfoCellRenderer,
            typeCellRenderer: TypeCellRenderer,
            lastLoginCellRenderer: LastLoginCellRenderer,
            passwordExpiresAtCellRenderer: PasswordExpiresAtCellRenderer,
            externalCellRenderer: ExternalCellRenderer,
            operationsCellRenderer: OperationsCellRenderer,
            chambersRenderer: ChambersRenderer,
            subTypeRenderer: SubTypeRenderer,
            defaultCellRenderWithTooltip: defaultCellRenderWithTooltip,
        },
        autoSize: "true",
        filterData: props.filter,
        searchfunc: props.filter,
        info: props.info,
        setInfo: props.setInfo,
        isPaginated: true,
        context: {
            SendActivationMail: props.sendActivationMail,
            SendPswReset: props.sendPswReset,
            delete: props.delete,
            deleteRepeal: props.deleteRepeal,
            AddNotification: props.addNotification,
            filter: props.filter,
            searchData: props.search,
            userLang: props.userLang,
        },
        minWidth : 100,
        hidePagination: true,
        customAction:
            <Grid>
                {hasRole(ROLES.GLOBAL_ADMINISTRATOR) &&
                    <LinkToButton to={"/users/whitelist"}>
                        <SubmitButton
                            type="submit"
                            variant="contained"
                            text={i18next.t("users.whitelist.title")}
                        />
                    </LinkToButton>
                }
                    <LinkToButton to={"/users/new"} style={{marginLeft: '10px'}}>
                        <SubmitButton
                            type="submit"
                            variant="contained"
                            text={i18next.t("users.new")}
                        />
                    </LinkToButton>
            </Grid>
    };

    return (
        <Table {...table} />
    );
}

function mapStateToProps(state) {
    return {
        userLang: state.authentication.user.language,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        sendActivationMail: (userId) => dispatch(userActions.sendActivationMail(userId)),
        sendPswReset: (userId) => dispatch(userActions.sendPasswordReset(userId)),
        delete: (userId) => dispatch(userActions.deleteRow(userId)),
        deleteRepeal: (userId) => dispatch(userActions.deleteRepeal(userId)),
        addNotification: (type, message) => dispatch(notificationsActions.addNotification(type, message)),
        filter: (search) => dispatch(userActions.filter(search)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
