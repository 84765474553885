import React, {} from "react";
import {Accordion, AccordionDetails, Grid} from "@material-ui/core";
import Header from "../../Common/Header";
import TxtField from "../../Common/TxtField";
import userWhitelistActions from "../../../store/userWhitelist/actions.js";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import i18next from "i18next";
import SubmitButton from "../../Common/SubmitButton";
import LinkToButton from "../../Common/LinkToButton";
import CancelButton from "../../Common/CancelButton";
import AccordionSum from "../../Common/AccordionSum";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import colors from "../../../styles/colors.scss";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { history } from "../../../store";

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        marginTop: theme.spacing(1),
    },
}));

function UserWhitelistForm(props) {
    const classes = useStyles();
    const { changeUpdateForm, updateForm, loadingForm, updateFormResponse } = props;
    const inputRef = React.useRef();

    const handleChange = (event) => {
        const val = event.target.files[0];
        props.changeUpdateForm("updateForm", { ...updateForm, [event.target.name]: val });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props
            .startUpdate(updateForm)
            .then((data) => {
                console.log("Sikeres fájlfeltöltés", data);
            })
            .catch((error) => {
                console.log("Hibás fájlfeltöltés", error);
            });
    }

    return (
        <>
        <Grid container spacing={2}>
            <Header
                title={i18next.t("sidebar.users")}
                breadcrumbs={{
                    master_datas: i18next.t("sidebar.master_data"),
                    users: i18next.t("sidebar.users"),
                    whitelist: i18next.t("sidebar.users.whitelist"),
                    whitelistImport: i18next.t("users.whitelist.import.title"),
                }}
            />
            <Grid item xs={12}>
                <Accordion expanded>
                    <AccordionSum id="panel1a-header" text={i18next.t("users.whitelist.import.title")} />
                    <AccordionDetails style={{display: "block"}}>
                        <form onSubmit={handleSubmit} noValidate={true}>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item>
                                    <TxtField
                                        label={i18next.t('import.file')}
                                        type="file"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                        margin="normal"
                                        name="file"
                                        ref={inputRef}
                                        onChange={(event) => handleChange(event)}
                                        inputProps={{accept: '.xlsx'}}
                                        disabled={loadingForm}
                                    />
                                </Grid>
                                <Grid item container xs={12} sm={12} spacing={2}>
                                    <Grid item>
                                        <SubmitButton text={i18next.t('users.whitelist.import.form.button')}
                                                      disabled={loadingForm}
                                                      type="submit"/>
                                    </Grid>
                                    <Grid item>
                                        <LinkToButton to="/users/whitelist">
                                            <CancelButton text={i18next.t('cancel')} disabled={loadingForm} />
                                        </LinkToButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>

        <Dialog open={updateFormResponse !== null}>
            <DialogTitle style={{ backgroundColor: updateFormResponse?.error ? colors.red : colors.primary, color: colors.white }}>
                {updateFormResponse?.success && i18next.t('users.whitelist.import.dialog.success.title')}
                {updateFormResponse?.error && i18next.t('users.whitelist.import.dialog.error.title')}
            </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {updateFormResponse?.success && i18next.t('users.whitelist.import.dialog.success.desc')}
                        {updateFormResponse?.error && i18next.t('users.whitelist.import.dialog.error.desc')+ updateFormResponse.error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CancelButton
                        text={i18next.t('users.whitelist.import.form.buttonClose')}
                        onClick={() => {
                            props.reponseDialogClose();
                            if(updateFormResponse?.success) {
                                history.push('/users/whitelist');
                            }
                        }}
                        disabled={props.loading}
                    />
                </DialogActions>
        </Dialog>
        </>
    );
}

function mapState(state) {
    const {changeUpdateForm, updateForm, loadingForm, updateFormResponse} = state.userWhitelist;
    return {changeUpdateForm, updateForm, loadingForm, updateFormResponse};
}

const actionCreators = {
    changeUpdateForm: userWhitelistActions.changeUpdateForm,
    startUpdate: userWhitelistActions.startUpdate,
    reponseDialogClose: userWhitelistActions.reponseDialogClose,
}

export default connect(mapState, actionCreators)(UserWhitelistForm);
